<template>
    <div  v-disable-all="!pageMeta.permissions.edit">
        <div v-if="!loading" class="rec-data-div" :class="pageMeta.component_name.toLowerCase()+'-page'">
            <form @submit.prevent="updateRecord">
                <div class="flex space-between">
                    <div class="name-id">
                        <FormClient
                            :client_id="client_id"
                            :siggys="original_signature_ids.length"
                            :form_data_id="record_id"
                            @getClient="getClient"
                        /><br>
                    </div>
                    <div class="align-right">
                        <FormCompany @getCompany="getCompany"/>
                    </div>
                </div>
                <div>
                    <h2 class="margin-0">{{ pageMeta.title }}</h2>
                </div>

                <button type="button" @click="signature_ids=[]" v-if="signature_ids.length && original_signature_ids.length">
                    Edit & Create Addendum
                </button>
                <button v-if="original_signature_ids.length && !signature_ids.length" class="align-right" type="button" @click="signature_ids=original_signature_ids">
                    Cancel Edit
                </button>
                <div class="dark-text header-w-bg" v-if="original_signature_ids.length && !signature_ids.length">
                    You are now editing the original signed document to create an addendum
                </div>

                <hr class="m-15" />

                <!-- put generated code BELOW here -->

                <div class="max800">
                    <p>Rogerian Solutions always strives to provide you with the best treatment experience as possible and to protect your rights as a client or patient at all times. One way of facilitating this is by informing you of your rights and educating you on how you can file a complaint. Your rights as a patient or client, including how to file a complaint, are listed as follows:</p>
                    <ol>
                        <li class="avoid-break">You have the right to not be denied access to services based off race, religion, ethnicity, disability, sexual orientation or HIV status.</li>
                        <li class="avoid-break">You have the right to receive services in the least restrictive environment available.</li>
                        <li class="avoid-break">You have the right to keep your AIDS/HIV status confidential and to receive AIDS/HIV testing in an anonymous and confidential manner.</li>
                        <li class="avoid-break">You have the right to nondiscriminatory access to services in accordance with the Americans with Disabilities Act.</li>
                        <li class="avoid-break">You have the right to request restrictions on certain use or disclosures of your protected health information. You can make this request at any time with Rogerian Solutions. Such a request will be reviewed and responded to by our Privacy Officer.</li>
                        <li class="avoid-break">You have the right to request an amendment to your protected health information and request an additional review if your request to amendment is denied. You have the right to include a disagreement disclosure pertaining to specific PHI that was not amended to your satisfaction. Your disagreement disclosure will have our rationale included to provide the reasons why your health record was not amended.</li>
                        <li class="avoid-break">You have the right to receive confidential communications pertaining to your protected health information and to keep all information pertaining to the receipt of the services you receive confidential as applicable with all federal and state laws.</li>
                        <li class="avoid-break">You have the right to inspect and copy your protected health information.</li>
                        <li class="avoid-break">You have the right to receive accounting of disclosures made pertaining to your protected health information.</li>
                        <li class="avoid-break">You have the right to receive a paper copy of your protected health information.</li>
                        <li class="avoid-break">You have the right to refuse treatment or any specific treatment procedure and be apprised of potential consequences of refusing such treatment and/or procedure.</li>
                        <li class="avoid-break">You have the right to file a written complaint or disagreement pertaining to the services to our CEO at:
                            <p><b>Chief Executive Officer<br/>11759 Southwest HWY STE 2<br/>Palos Heights, IL. 60463</b></p>
                        </li>
                    </ol>
                    
                    <p>My signature indicates I have received a copy of my patient rights.</p>
                </div>

                <!-- put generated code ABOVE here -->

                <div class="align-right" v-if="!signature_ids.length">
                    <button v-if="original_signature_ids.length && !signature_ids.length" class="align-right inline-block secondary right-15" type="button" @click="signature_ids=original_signature_ids">
                        Cancel Edit
                    </button>
                    <ConfirmButton class="inline-block" @click="updateRecord()" :canedit="isEditable" />

                </div>
                <FormSignature :user_id="user_id" :signature_ids="signature_ids" ref="form_signatures" @getSignatures="getSignatures" v-if="signature_ids.length > 0"/>
                <FormSignatureButton
                    :rec="rec"
                    :original_rec="original_rec"
                    :signatures_info="signatures_info"
                    :fileName="pageMeta.component_name"
                    :signature_ids="signature_ids"
                    :record_id="record_id"
                    @resetView="reloadData"
                    @fail="fail2sign"
                    @showModal="showPdfModal"
                    :save="updateRecord"
                />
            </form>
            <VIfWorkableModal
                :hasCloseButton="false"
                data-html2canvas-ignore="true"
                v-if="showCreatePdfModal"
            >
                <SignConvertPdfModal />
            </VIfWorkableModal>
        </div>
    </div>
</template>
<script>
    import SignConvertPdfModal from '@/components/formpieces/SignConvertPdfModal'
    import FormClient from '@/components/formpieces/FormClient'
    import FormCompany from '@/components/formpieces/FormCompany.vue';
    import FormSignature from '@/components/formpieces/FormSignatureNew';
    import FormSignatureButton from '@/components/formpieces/FormSignatureButtonNew'
    import VIfWorkableModal from '@/components/general/modals/vIfWorkableModal.vue';
    import DynamicImage from '@/components/general/image/Image';
    import { companies, file } from '@/util/apiRequests';
    import { dryalex } from '@/mixins/dryalex'
    import { merge } from 'lodash'

    export default {
        name: 'ClientRights',
        props: {
            record_id: {
                type: [String, Number],
                required : false,
                default : 0
            },
        },
        components: { FormClient, FormSignature, VIfWorkableModal, DynamicImage, FormCompany, FormSignatureButton, SignConvertPdfModal },
        data() {
            return {
                loading     : 1,
                updating    : 0,
                isEditable  : 1,
                user_id     : 0,
                client_id   : 0,
                page_id     : 0,
                rec         : {},
                original_rec : {},
                signature_ids : [],
                original_signature_ids : [],
                showCreatePdfModal : false,
                api_signs_needed: null,
                signatures_info: null,
                client: {},
                company: {},
                rows: [],
                level_id: 0,
                level: 'company',
                options: [],
                this_record_id : 0,
                router_push : 0,
                file
            }
        },
        methods: {
            async fail2sign() {
                this.showPdfModal(false)
            },
            async reloadData(signature){
                this.showCreatePdfModal = false;
                this.signature_ids.push(signature)
            },
            showPdfModal(bool){
                this.showCreatePdfModal = bool;
            },
            getClient(data){
                this.client = data;
            },
            getCompany(data){
                this.company = data;
            },
            //signatures that come from the FormSignatures component
            getSignatures(data){
                this.signatures_info = data ? data : [];
            },
            async updateRecord(e) {
                if (!this.updating) {
                    this.updating  = 1
                    try{e.preventDefault()}catch(error){/**/}

                    let xthis = await dryalex.form_data_update_record(this, e)
                    merge(this, xthis)
                    if (this.router_push) {
                        this.router_push = 1
                        this.$forceUpdate()
                    }
                    this.original_signature_ids = this.signature_ids
                }
            },
            async init() {
                let xthis = await dryalex.form_data_init(this)
                merge(this, xthis)
                this.loading = 0
            },
        },
        async created() {
            await this.init()
        },
        watch: {
            showCreatePdfModal(newVal){
                if(window.innerWidth >= 768 && newVal == true) this.openModalOverlay();
                if(newVal == false){this.closeModalOverlay()}
            },
        }
    }
</script>